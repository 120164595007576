import {NavLink, Link} from 'react-router-dom'
import './index.scss'
import cart from '../../asset/icon/cart-shop.png'

const Header = () => {
  return (
    <div className="header">
      <Link to="/index">
        <div className='header-logo'></div>
      </Link>
      <div className='header-menus'>
        <NavLink to="/index" className='header-menus_item'>首页</NavLink>
        <NavLink to="/list" className='header-menus_item'>所有商品</NavLink>
        <NavLink to="/login" className='header-menus_item'>会员登录</NavLink>
      </div>
      <div className='header-cart'>
        <img className='header-cart_icon' src={cart} alt="" />
        购物车
        <div className='header-cart_list'>
          {/* <div className='header-cart_list-good'>
            <img className='header-cart_list-good-img' src="" alt="" />
            <div className='header-cart_list-good-title'>标题</div>
            <div className='header-cart_list-good-lf'>
              <div className='amount'>x数量</div>
              <div className='price'>金额</div>
            </div>
          </div> */}
          <b>暂无商品</b>
        </div>
      </div>
    </div>
  )
}

export default Header