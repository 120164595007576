import './index.scss'
import license from '../../asset/icon/license.png'

const Footer = () => {
  const handleGoTop = () => {
    window.scrollTo({top: 0})
  }
  return (
    <footer className="footer">
      <div className="footer-left">
        <div className="footer-left_name">连山加（上海）信息技术有限公司</div>
        {/*eslint-disable  */}
        <a className="footer-left_mail" href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91310112MA1GEJJQ5W-SAIC_SHOW_10000091310112MA1GEJJQ5W1724900218495&signData=MEQCIH2Nb6PMGyBgNGko6CpVmgtYc4vuZTL6HyBky71xvLUXAiBbQUaI9QhXAfjVhiC2BEs3EiaECm63J2Vz8kbuneEOyw==" target="_blank" ><img  src={license} width={16} height={16} alt="" /></a>
        {/*eslint-disable  */}
        <a className="footer-left_mail" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备2021030152号</a>
        <div className="footer-left_copy">Copyright © 连山加（上海）信息技术有限公司 All Rights Reserved.</div>
        {/* <a href="mailto:service@vaxee.co" title="service@vaxee.co" className="footer-left_mail">service@vaxee.co</a> */}
      </div>
      <div className='footer_go-top' onClick={handleGoTop}>TOP</div>
    </footer>
  )
}

export default Footer